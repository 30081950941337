export default (props) =>  {
    const style={
        position:'absolute',
        bottom:0,
        height:50,
        width:'100%',
        textAlign:'center',
        ...props.style
    }
    return (
        <div style={style}>{props.text}</div>
    )
}




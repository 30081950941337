import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"


//import '../index.tailwind.css'
// Markdown
export default ({children}) => {
  return (
    <div className="post">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]} 
        children={children} 
      /> 
    </div>
  );
};



import axios from 'axios'
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serverFetch = (irl, username, password, handleResult) => {
    const url= irl.slice(0,4).toLowerCase().localeCompare('http')===0?irl:apiBaseUrl + irl
    // alert(url)
    // console.log('fetch url:', url);
    axios({	
        method: 'get',
        url,
        auth: {
            username,
            password,
        }
    })
    .then(response => {
        if (response.data.status === 'OK') {
            const result = response.data?response.data.result:[]
            handleResult(result);
        } else { 
            alert(response.data.status + ': Problem in serverFetch calling url:' + url)
        }    
    })
    .catch(e => {
        const errorMessage = 'url= <' + url + '> ERROR:' + JSON.stringify(e)
        alert(errorMessage)
        console.log('(function: functions/fetch) Error message:', errorMessage);
        handleResult([]);
    });
}

export const serverFetchRawData = (irl, username, password, handleResult) => {
    const url= irl.slice(0,4).toLowerCase().localeCompare('http')===0?irl:apiBaseUrl + irl
    alert(url)
    // console.log('fetch url:', url);
    axios({	
        method: 'get',
        url,
        auth: {
            username,
            password,
        }
    })
    .then(response => {
        const data = response.data?response.data:{}
        if (data.status === 'OK') {
            handleResult(data);
        } else { 
            alert(response.data.status?response.data.status:'?????' + ': Problem in serverFetchRawData calling url:' + url + ' message:' + response.data.message?response.data.message:'?????')
        }    
    })
    .catch(e => {
        const errorMessage = 'url= <' + url + '> ERROR:' + JSON.stringify(e)
        alert(errorMessage)
        console.log('(function: functions/fetch) Error message:', errorMessage);
        handleResult([]);
    });
}


export const fetchEmployees = (setFormFields, formFieldsFunc) =>{
    serverFetch('/fetchRows?tableName=tbl_employee', '', '', 
        result=>{setFormFields(formFieldsFunc(result.sort((a,b)=>a.sequenceNumber?a.sequenceNumber-b.sequenceNumber:a.id-b.id).map(it=>it.name)))})
        
}

export default serverFetch




import Markdown from "./components/Markdown";
import FilesInDir from "./components/FilesInDir"
import FolderTree from "./components/FolderTree"
import 'bulma/css/bulma.min.css'

const style = {
  width:'99vw',
  margin:'auto'
}

function App() {
  return (
    <div className='content' style={style}>
          <FolderTree subdir='docs' />
    </div>
  );
}

export default App;

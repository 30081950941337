import {useState, useEffect} from "react"
import FolderTree, { testData } from 'react-folder-tree';
import Markdown from '../components/Markdown'
import serverFetch from '../services/serverFetch'
import serverPost from '../services/serverPost'
import { FaEdit, FaRegFolder, FaRegFile, FaCheck } from 'react-icons/fa';
import { MdDelete, MdOutlineSave, MdOutlineSaveAs, MdCancel, MdSaveAs } from 'react-icons/md';
import { BUTTON_STYLE } from '../services/const';
import StatusLine from '../components/StatusLine'

import 'react-folder-tree/dist/style.css';

export default ({subdir}) => {
    const [md, setMd] = useState()
    const [edit, setEdit] = useState(undefined)
    const [showTree, setShowTree] = useState(false)
    const [treeState, setTreeState] = useState();
    const [initState, setInitState] = useState()
    const [nodeState, setNodeState] = useState()
    const [showPreview, setShowPreview] = useState(true)
    const [buttonStyle, setButtonStyle] = useState(BUTTON_STYLE.DEFAULT)

    /*{
        // reserved keys, can customize initial value
        name: 'root node',  
        // internal keys (auto generated), plz don't include them in the initial data
        path: [],    // path is an array of indexes to this node from root node
        _id: 0,
        // not reserved, can carry any extra info about this node
        url: subdir,
      }
    )*/


    const fetchTree = () => {
        const handleResult = result => {
            setInitState(result)
            setTreeState(result)
        }  
        const irl = "/getFolderTree?subdir=" + subdir
        serverFetch(irl, '', '', handleResult)
    }    

    useEffect(()=>{
        fetchTree()
    },[])

    const onTreeStateChange = (state, e) => {/*alert(JSON.stringify(state));*/ setTreeState(state)}

    const FolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;

        
    
        // custom event handler
        const handleClick = () => {  
            alert('FolderIcon name:' + name + JSON.stringify(nodeData))
            setNodeState(nodeData)
            defaultOnClick()
        };
        return <FaRegFolder onClick={ handleClick } />
    }


    const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;

    
        // custom event handler
        const handleClick = () => {  
            if (edit) {
                alert('Du editerar en annan fil. Lämna eller spara denna innan du öppnar en ny.')
            } else {
                // alert('Name:' + nodeData.name)
                if (name === 'new file') {
                    setMd('# New file')
                    defaultOnClick()
                } else {    
                    setNodeState(nodeData)
                    setEdit(false)
                    const irl = '/getFileContent?url=' + url + '&filename=' + name
                    serverFetch(irl, '', '', result => {defaultOnClick(); setMd(result)})
                }    
            }    
        };
        return <FaRegFile style={buttonStyle} onClick={ handleClick } />
    }


    const EditIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;


        // custom event handler
        const handleClick = () => {   
            setNodeState(nodeData)
            setEdit(true)
            defaultOnClick()
        };
    
        // custom Style
        return <FaEdit onClick={ handleClick } />;
      };
    
      const CancelIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;


    
        // custom event handler
        const handleCancel = () => {   
            setMd(undefined)
            setEdit(false)
            setNodeState(undefined)
            // alert('Cancel the operation')
            defaultOnClick()
        };
        return <MdCancel onClick={ handleCancel } />
      };

      const OKIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;


        const handleClick =() => {
            defaultOnClick()
            const data = {
                name, 
                path,
                url, 
                md,
                text:md,
            }
            alert(name)    
            
            /*const postUrl = '/postFileContent'
            if (edit) {
                setButtonStyle(BUTTON_STYLE.CLICKED)
                serverPost(postUrl, '', '', data, handleReply)
            } 
            */
        }
        return <FaCheck style={buttonStyle} onClick={ handleClick } />
      };

      const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const {
          path,
          url,
          name,
          checked,
          isOpen,
          ...restData
        } = nodeData;

        const handleReply = reply => {
            if (reply.status === 'OK') {
                alert('File deleted successfully')
                setTimeout(() => setButtonStyle(BUTTON_STYLE.DEFAULT), 5000);
                defaultOnClick()
            } else {
                alert('ERROR: Failed to delete file. Message:' + reply.message)
            }
        }

        // custom event handler
        const handleClick = () => {   
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Are you sure that you want to remove file ' + name + ' from disk ?')===true) {
                const irl = '/removeFile?url=' + url + '&filename=' + name
                const data = {
                    url,
                    name,
                }

                serverPost(irl, '', '', data, handleReply)
            } 
        };
        return <MdDelete onClick={ handleClick } />
      };



      const handleSave = () => {
        const data = {
            name:nodeState.name, 
            path:nodeState.path,
            url:nodeState.url,
            text:md,
        }
        const handleReply = reply => {
            if (reply.status === 'OK') {
                setButtonStyle(BUTTON_STYLE.SAVED)
                setTimeout(() => {
                    setMd(undefined)
                    setNodeState(undefined)
                    setEdit(false)
                    setButtonStyle(BUTTON_STYLE.DEFAULT)
                }, 2000);
            } else {
                setButtonStyle(BUTTON_STYLE.ERROR) 
                setTimeout(() => setButtonStyle(BUTTON_STYLE.DEFAULT), 5000);
                alert('ERROR:' + JSON.stringify(reply))
            }    
        }
        setButtonStyle(BUTTON_STYLE.CLICKED)
        const postUrl = '/postFileContent'
        serverPost(postUrl, '', '', data, handleReply)
      } 

      const handleSaveAs = () => {
        let name = prompt("Spara fil som ", nodeState.name);
        if (name !== null) {
            const handleReply = reply => {
                if (reply.status === 'OK') {
                    setButtonStyle(BUTTON_STYLE.SAVED)
                    setTimeout(() => {
                        setMd(undefined)
                        setNodeState(undefined)
                        setEdit(false)
                        setButtonStyle(BUTTON_STYLE.DEFAULT)
                        fetchTree()
                    }, 2000);
                } else {
                    setButtonStyle(BUTTON_STYLE.ERROR) 
                    setTimeout(() => setButtonStyle(BUTTON_STYLE.DEFAULT), 5000);
                    alert('ERROR:' + JSON.stringify(reply))
                }    
              }
        
            setButtonStyle(BUTTON_STYLE.CLICKED)
            const postUrl = '/postFileContent'
            const dirname = nodeState.url.substring(0,nodeState.url.lastIndexOf('/')+1);
            // alert('XXXXX dirname = ' +  dirname)
            const data = {
                name:name, 
                url:dirname + '/' + name,
                text:md,
            }
            serverPost(postUrl, '', '', data, handleReply)
        } 
      }  

      const onNameClick = ({ defaultOnClick, nodeData }) => {
        const {
            // internal data
            path, 
            name, 
            checked, 
            isOpen, 
            url,
            ...whateverRest
        } = nodeData;
    
        // alert('nodeData:' + JSON.stringify(nodeData));
        if (!nodeData.children) {
            const irl = '/getFileContent?url=' + url + '&filename=' + name
            setNodeState(nodeData)
            serverFetch(irl, '', '', result => setMd(result))
            setEdit(false) 
            defaultOnClick()
        } else {
            alert('Folder')    
        }    
        defaultOnClick();
    };
    
    const toggleShowTree = () => setShowTree(showTree?false:true)
      
    const [i,j,k,l] = nodeState?nodeState.path?nodeState.path:[0,0,0,0]:[0,0,0,0]
    return (
        <>  
            Node state:{JSON.stringify(nodeState)} &nbsp;  {i},{j},{k},{l}
            {initState?
                <div className='columns is-left' >
                    {edit?null:
                        <div className='column is-2 ' style={{fontSize:14}}>    
                            <h1>Filträd</h1>
                            <FolderTree
                                data={initState}
                                onNameClick={onNameClick}
                                onChange = {onTreeStateChange}
                                initOpenStatus='open'
                                showCheckbox={false}
                                iconComponents={{
                                    FileIcon,
                                    EditIcon,
                                    DeleteIcon,
                                    CancelIcon,
                                    OKIcon,
                                    FolderIcon,
                                    /* other custom icons ... */
                                }}
                            />    
                            {md?<button onClick={()=>setShowPreview(showPreview?false:true)}>{showPreview?'Hide Preview':'Preview'}</button>:null}
                            <button onClick={toggleShowTree}>{showTree?'Göm trädstruktur':'Visa trädstruktur'}</button>
                            {showTree?
                                <div style={{fontSize:8}}>
                                    {JSON.stringify(treeState)}
                                </div>
                                :null
                            }
                            <StatusLine text='Ready' style={buttonStyle} />
                        </div>
                    }
                    {edit?
                        <div className={'column is-6'}> 
                            <MdOutlineSave style={buttonStyle} onClick={()=>handleSave()} />
                            <MdOutlineSaveAs style={buttonStyle} onClick={()=>handleSaveAs()} />
                            <MdCancel onClick={()=>{alert('Lämna'); setNodeState(undefined); setEdit(false)}} />
                            <p/>
                            <textarea cols={78} rows={40} onChange={e=>setMd(e.target.value)}>{md}</textarea>
                        </div>    
                    :null}    
                    :{
                        md&&showPreview?
                            <div className='column'>
                                <Markdown children={md} />
                            </div>
                        :null    
                    }    
                </div>
                
            :null}
            
        </>
    );
};